import React, { useContext, useEffect, useState, useReducer } from 'react'
import { LinearProgress } from '@material-ui/core'
import { StoreContext } from '../StoreContext'

const incrementor = (state, action) => {
  return state + action.increment_by
}

const Timer = ({
  duration,
  onTimeExpired = null,
  onTimeUsed = null,
  classes,
  percentInitializer = 0,
}) => {
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [timer, setTimer] = useState(null)
  const [percentComplete, incrementPercentComplete] = useReducer(
    incrementor,
    percentInitializer
  )
  const { stopTimer, timeUsed } = useContext(StoreContext)
  // Capture start time
  useEffect(() => {
    if (startTime === null) {
      setStartTime(performance.now())
    }
  }, [startTime])

  // Start timer and save ID
  useEffect(() => {
    if (!timer) {
      const timerID = setInterval(() => {
        // We want the total time to be duration * 1000
        // We will increment the percentage once every 100 MS (1000/100 = 10)
        const numberOfIncrements = duration * 10
        // 100% / number of increments = amount each increment is worth
        const percentageIncrement = 100 / numberOfIncrements
        incrementPercentComplete({ increment_by: percentageIncrement })
      }, 100)
      setTimer(timerID)
    }
    return () => {
      if (timer && percentComplete >= 100) {
        clearInterval(timer)
      }
    }
  }, [timer, duration, percentComplete])

  useEffect(() => {
    if (percentComplete >= 1 && percentComplete <= 100 && onTimeUsed !== null) {
      onTimeUsed(percentComplete)
    }
  }, [percentComplete, onTimeUsed])

  // When timer animation is complete, clear interval and record endTime
  useEffect(() => {
    if (percentComplete >= 100) {
      clearInterval(timer)
      if (!endTime) {
        setEndTime(performance.now())
      }
    }
  }, [percentComplete, endTime, timer])

  useEffect(() => {
    if (stopTimer) {
      clearInterval(timer)
    }
  }, [timer, stopTimer, percentComplete, duration, timeUsed])

  // If the timer is done, fire the callback!
  useEffect(() => {
    if (endTime !== null) {
      const timeTaken = Math.round((endTime - startTime) / 10) / 100
      if (onTimeExpired !== null) {
        onTimeExpired(timeTaken)
      }
    }
  }, [endTime, onTimeExpired, startTime])

  return (
    <LinearProgress
      variant="determinate"
      value={percentComplete}
      color="primary"
      classes={classes}
    />
  )
}

export default Timer
