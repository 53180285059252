import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import SliderComp from './SliderComp'
import { SURVEY_SLIDER, SURVEY_MC, SURVEY_MS } from '../../constants'
import MultipleChoice from './MultipleChoice'
import MultiSelect from './MultiSelect'
import TextFieldComp from './TextFieldComp'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  selectAllText: styles.selectAllText,
  faceHero: styles.faceHero,
})

const Survey = ({ q }) => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const [responseItems, setResponseItems] = useState(null)
  const [questionItem, setQuestionItems] = useState(null)
  const [hasImage] = useState('image' in q)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [skippingQuestion, setSkippingQuestion] = useState(false)
  const [skipChecked, setSkipChecked] = useState(false)
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  const [preloadedImage] = useState(new Image())

  const { setQuestionStartTime, incrementLoadingPanelCount } = store

  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  useEffect(() => {
    function handleSkipQuestion() {
      if (
        store.skipSurveyQuestions.find((elem) => {
          return elem === q.id
        })
      ) {
        store.setUserResponse('Skipped')
        store.setHasTextInput(false)
        store.setTextInput(null)
        setSkippingQuestion(true)
        store.incrementAttemptNumber()
        store.nextQuestionWithShortDelay()
      }
      setSkipChecked(true)
    }
    if (!skipChecked) {
      handleSkipQuestion()
    }
  }, [q, store, skipChecked])

  useLayoutEffect(() => {
    const order = []
    const questionTextOrder = []
    if (q.question_type === SURVEY_SLIDER) {
      let skipNA = null
      if ('skip_na' in q) {
        skipNA = q.skip_na
      }
      questionTextOrder.push(
        <Typography key={`qtext-slider-${q.id}`}>{q.question_text}</Typography>
      )
      order.push(
        <SliderComp
          min={q.options.min}
          max={q.options.max}
          step={q.options.step}
          hasNotApplicable={q.options.has_not_applicable}
          minLabel={q.options.min_label}
          maxLabel={q.options.max_label}
          key={`question-slider-${q.id}`}
          skipNA={skipNA}
        />
      )
    } else if (q.question_type === SURVEY_MC) {
      questionTextOrder.push(
        <Typography key={`qtext-mc-${q.id}`}>{q.question_text}</Typography>
      )
      order.push(
        <MultipleChoice
          choices={q.options.choices}
          key={`question-mc-${q.id}`}
        />
      )
    } else if (q.question_type === SURVEY_MS) {
      questionTextOrder.push(
        <>
          <Typography key={`qtext-ms-${q.id}`}>{q.question_text}</Typography>
          <Typography
            variant="body2"
            className={classes.selectAllText}
            align="center"
          >
            {store.getString('selectAllApply')}
          </Typography>
        </>
      )
      let skipNA = null
      let multiSelectFollowup = null
      if ('multi_select_followup' in q) {
        multiSelectFollowup = q.multi_select_followup
      }
      if ('skip_na' in q) {
        skipNA = q.skip_na
      }
      order.push(
        <MultiSelect
          choices={q.options.choices}
          key={`question-ms-${q.id}`}
          multiSelectFollowup={multiSelectFollowup}
          skipNA={skipNA}
        />
      )
    } else {
      questionTextOrder.push(<Typography key={3}>{q.question_text}</Typography>)
      order.push(<TextFieldComp key={2} />)
    }
    setResponseItems(order)
    setQuestionItems(questionTextOrder)
  }, [setResponseItems, q, classes.selectAllText, store])

  if (hasImage && preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (hasImage) {
      if (imageLoaded && preloadedImage.complete) {
        return
      }
      if (preloadedImage.complete) {
        // Ensure we display the loading spinner for at least 3 seconds
        if (!imageLoaded) {
          setTimeout(() => {
            setImageLoaded(preloadedImage.complete)
            setQuestionStartTime(performance.now())
            incrementLoadingPanelCount()
          }, 3000)
        } else {
          // Most likely redundant, reset to True
          setImageLoaded(preloadedImage.complete)
        }
      } else {
        // Check every 1/10 of a second for the first second
        if (sleepCounter >= 5 && imageLoaded) {
          setImageLoaded(false) // Only display loading spinner if preload-check takes > 300 ms
        }
        const sleepDuration = sleepCounter > 10 ? 1000 : 100
        incrementSleep(sleepDuration)
      }
    } else {
      setImageLoaded(true)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    hasImage,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (skippingQuestion || !imageLoaded) {
    return <Loading />
  }
  const cardImage = []
  if (hasImage) {
    cardImage.push(
      <CardContent key={4}>
        <CardMedia className={classes.faceHero} image={q.image} />
      </CardContent>
    )
  }

  return (
    <Card className={classes.width95}>
      {store.isPractice && (
        <CardContent>
          <PracticeChip />
        </CardContent>
      )}
      {cardImage}
      <CardContent>{questionItem}</CardContent>
      {responseItems}
    </Card>
  )
}

export default Survey
