import { useCallback, useState } from 'react'

const usePreventDoubleClick = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentTimeout, setCurrentTimeout] = useState(null)
  const handleClick = useCallback(
    (callback) => {
      if (isSubmitting || currentTimeout) {
        return () => {
          clearTimeout(currentTimeout)
        }
      }
      setCurrentTimeout(null)
      setIsSubmitting(true)
      callback()
      return () => {
        if (currentTimeout) {
          clearTimeout(currentTimeout)
        }
      }
    },
    [isSubmitting, currentTimeout] // No need for `isSubmitting` in dependencies because we're using a ref
  )

  return [handleClick, isSubmitting]
}

export default usePreventDoubleClick
