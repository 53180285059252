import React, { useState, useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import Badge from '../Badge'
import styles from '../../css-styles'
import usePreventDoubleClick from '../../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({ yesNoAnswerButton: styles.yesNoAnswerButton })

const SignAnswerButton = ({ answer }) => {
  const classes = useStyles()
  const [attempted, setAttempted] = useState(false)
  const store = useContext(StoreContext)
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay
  const onClickHandler = () => {
    setAttempted(true)
    store.setDidAnswerCorrectly(answer.isCorrect)
    store.incrementAttemptNumber()
    store.nextQuestionWithShortDelay()
  }
  return (
    <Fab
      className={classes.yesNoAnswerButton}
      variant="extended"
      size="large"
      disabled={store.attemptNumber !== 0 || isSubmitting}
      onClick={() => {
        handleClick(onClickHandler)
      }}
    >
      {answer.text}
      {store.hasVisualFeedback && attempted && (
        <Badge isCorrect={answer.isCorrect} />
      )}
    </Fab>
  )
}

export default SignAnswerButton
