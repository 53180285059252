import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'
import { StoreContext } from '../../StoreContext'
import Badge from '../Badge'
import styles from '../../css-styles'
import usePreventDoubleClick from '../../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({
  answerImgDisabled: styles.answerImgDisabled,
  answerImg: styles.answerImg,
})

const FaceButton = ({ answer }) => {
  const [attempted, setAttempted] = useState(false)
  const classes = useStyles()
  const store = useContext(StoreContext)
  const [preloadedImage] = useState(new Image())
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = answer.image
  }

  const disabledState =
    attempted ||
    isSubmitting ||
    (store.attemptNumber !== 0 && !store.isPractice)

  const onClickHandler = () => {
    setAttempted(true)
    store.setDidAnswerCorrectly(answer.isCorrect)
    store.incrementAttemptNumber()
    if (!store.isPractice || answer.isCorrect) {
      store.nextQuestionWithShortDelay()
    }
  }

  return (
    <Fab
      variant="extended"
      style={{ height: 'calc(var(--rvh, 1vh) * 25)', width: '95%' }}
      disabled={disabledState}
      onClick={() => {
        handleClick(onClickHandler)
      }}
    >
      <span
        className={
          disabledState ? classes.answerImgDisabled : classes.answerImg
        }
        style={{
          backgroundImage: `url(${answer.image})`,
        }}
      />
      {store.hasVisualFeedback && attempted && (
        <Badge isCorrect={answer.isCorrect} />
      )}
    </Fab>
  )
}

export default FaceButton
