import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import PracticeChip from '../PracticeChip'
import AnswerButtonTrio from '../AnswerButtonTrio'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import Timer from '../Timer'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  faceHero: styles.faceHero,
  threeJobs: styles.threeJobs,
})

const Matching = ({ q }) => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  useQuestionStartTime()
  const { setHasError } = store

  const [faceButtons, setFaceButtons] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(false)
  const testVariationKey = store.data.testVariation.key
  const [preloadedImage] = useState(new Image())
  const { setQuestionStartTime, incrementLoadingPanelCount } = store

  useLayoutEffect(() => {
    if (testVariationKey === '') {
      const newErr = new Error(
        `Missing spreadsheet key for this test variation.`
      )
      newErr.errorCode = 'DE3'
      store.setErrorState({ type: 'error', error: newErr })
    }
    if (!Object.hasOwnProperty.call(q.order_matching, testVariationKey)) {
      const newErr = new Error(
        `Unable to find test variation key: ${testVariationKey} in Keyed F Name Orderings for ${q.name}`
      )
      newErr.errorCode = 'DE4'
      store.setErrorState({ type: 'error', error: newErr })
    }

    const order = q.order_matching[testVariationKey].split(',')
    const orderedButtons = []

    order.forEach((position) => {
      const trimmedPosition = position.trim()
      if (trimmedPosition === 'T') {
        orderedButtons.push(
          <AnswerButtonTrio
            answer={{ value: q.target_name, isCorrect: true }}
            key={0}
          />
        )
      } else if (trimmedPosition === '1') {
        orderedButtons.push(
          <AnswerButtonTrio
            answer={{
              value: q.foil_name_1,
              isCorrect: false,
            }}
            key={1}
          />
        )
      } else if (trimmedPosition === '2') {
        orderedButtons.push(
          <AnswerButtonTrio
            answer={{
              value: q.foil_name_2,
              isCorrect: false,
            }}
            key={2}
          />
        )
      } else {
        setHasError(true)
      }
    })

    setFaceButtons(orderedButtons)
  }, [q, setHasError, testVariationKey, store])
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.target_image
  }

  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card className={classes.width95}>
      {store.isPractice && (
        <CardContent>
          <PracticeChip />
        </CardContent>
      )}
      <Grow in>
        <CardContent>
          <CardMedia className={classes.faceHero} image={q.target_image} />
        </CardContent>
      </Grow>
      <Grow in>
        <CardActions className={classes.threeJobs} disableSpacing>
          {faceButtons}
        </CardActions>
      </Grow>
      <Timer duration={store.getTimer()} onTimeExpired={store.onTimeExpired} />
    </Card>
  )
}

export default Matching
