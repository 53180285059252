import React, { useState, useContext, useEffect } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import CorrectPairButton from './CorrectPairButton'
import ConfidenceButton from './ConfidenceButton'
import Timer from '../Timer'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  faceHero: styles.faceHero,
  threeJobs: styles.threeJobs,
  justifySpaceEvenly: styles.justifySpaceEvenly,
})

const QuestionFaceNameRecognize = ({ q }) => {
  const classes = useStyles()
  const [answered, setAnswered] = useState(false)
  const [answeredConfident, setAnsweredConfident] = useState(false)
  const {
    askUserAreYouThere,
    didAnswerCorrectly,
    getString,
    getTimer,
    isPractice,
    onTimeExpired,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  } = useContext(StoreContext)

  const timeExpiredCallback =
    didAnswerCorrectly === null ? askUserAreYouThere : onTimeExpired
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card className={classes.width95}>
      <Grow in>
        <CardContent>
          {isPractice && <PracticeChip />}
          <Typography>
            {answered ? getString('howConfident') : getString('isCorrectPair')}
          </Typography>
        </CardContent>
      </Grow>
      <Grow in>
        <CardContent>
          <Card>
            <CardContent>
              <CardMedia
                className={classes.faceHero}
                image={q.image}
                title={q.image_caption}
              />
            </CardContent>
            <Typography variant="h4" gutterBottom>
              {q.image_caption}
            </Typography>
          </Card>
        </CardContent>
      </Grow>
      <Grow in>
        <CardActions
          className={answered ? classes.threeJobs : classes.justifySpaceEvenly}
          disableSpacing
        >
          {answered ? (
            <>
              <ConfidenceButton
                confident
                answered={answeredConfident}
                setAnswered={setAnsweredConfident}
              />
              <ConfidenceButton
                answered={answeredConfident}
                setAnswered={setAnsweredConfident}
              />
            </>
          ) : (
            <>
              <CorrectPairButton
                setAnswered={setAnswered}
                isCorrect={q.is_correct}
              >
                {getString('yes')}
              </CorrectPairButton>
              <CorrectPairButton
                setAnswered={setAnswered}
                isCorrect={!q.is_correct}
              >
                {getString('no')}
              </CorrectPairButton>
            </>
          )}
        </CardActions>
      </Grow>
      {isPractice && !answered && (
        <Timer duration={getTimer()} onTimeExpired={timeExpiredCallback} />
      )}
    </Card>
  )
}

export default QuestionFaceNameRecognize
