import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import FaceNameButton from './FaceNameButton'
import CircularDeterminate from '../CircularDeterminate'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  faceHero: styles.faceHero,
  justifyContentCenter: styles.justifyContentCenter,
})

const QuestionFaceNameLearn = ({ q }) => {
  const classes = useStyles()
  const {
    getString,
    isPractice,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  } = useContext(StoreContext)
  const [isDisabled, setIsDisabled] = useState(true)
  const [buttonContent, setButtonContent] = useState(getString('loading'))

  useEffect(() => {
    if (isDisabled) {
      setButtonContent(
        <CircularDeterminate callback={() => setIsDisabled(false)} size={50} />
      )
    } else {
      setButtonContent(getString('nextButtonLabel'))
    }
  }, [isDisabled, setButtonContent, getString])

  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  const [preloadedImage] = useState(new Image())

  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography>{getString('tryRememberPair')}</Typography>
      </CardContent>
      <Grow in={isDisabled}>
        <CardContent>
          <Card>
            <CardContent>
              <CardMedia
                className={classes.faceHero}
                image={q.image}
                title={q.image_caption}
              />
            </CardContent>
            <Typography variant="h4" gutterBottom>
              {q.image_caption}
            </Typography>
          </Card>
        </CardContent>
      </Grow>
      <CardActions className={classes.justifyContentCenter}>
        <FaceNameButton disabled={isDisabled}>{buttonContent}</FaceNameButton>
      </CardActions>
    </Card>
  )
}

export default QuestionFaceNameLearn
