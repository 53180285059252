import React, { useState, useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import Badge from './Badge'
import { RC_CATEGORIES_RECALL } from '../constants'
import styles from '../css-styles'
import usePreventDoubleClick from '../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({
  answerButton: styles.answerButton,
  answerImgDisabled: styles.answerImgDisabled,
  answerImg: styles.answerImg,
})

const AnswerButton = ({ answer }) => {
  const classes = useStyles()
  const [attempted, setAttempted] = useState(false)
  const store = useContext(StoreContext)
  const phase = store.getPhase(store.afterFeedback)
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay
  const isFaded =
    (phase.react_component === RC_CATEGORIES_RECALL &&
      !store.isPractice &&
      store.attemptNumber > 0) ||
    (attempted && !answer.is_correct) ||
    (store.didAnswerCorrectly && !answer.is_correct)

  const isDisabled =
    (phase.react_component === RC_CATEGORIES_RECALL &&
      !store.isPractice &&
      store.attemptNumber > 0) ||
    (attempted && !answer.is_correct) ||
    store.didAnswerCorrectly

  const onClickHandler = () => {
    setAttempted(true)
    store.setDidAnswerCorrectly(answer.is_correct)
    store.incrementAttemptNumber()
    if (phase.react_component === RC_CATEGORIES_RECALL && !store.isPractice) {
      store.nextQuestionWithShortDelay()
    }
  }

  return (
    <Fab
      className={classes.answerButton}
      variant="extended"
      size="large"
      disabled={isDisabled || isSubmitting}
      onClick={() => {
        handleClick(onClickHandler)
      }}
    >
      {answer.image ? (
        <span
          className={isFaded ? classes.answerImgDisabled : classes.answerImg}
          style={{
            backgroundImage: `url(${answer.image})`,
          }}
        />
      ) : (
        answer.value
      )}
      {store.hasVisualFeedback && attempted && (
        <Badge isCorrect={answer.is_correct} />
      )}
    </Fab>
  )
}

export default AnswerButton
