import React, { useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import usePreventDoubleClick from '../../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({
  threeJobsAnswerButton: styles.threeJobsAnswerButton,
})

const ConfidenceButton = ({ answered, confident, setAnswered }) => {
  const classes = useStyles()
  const { getString, recordConfidenceLevel, nextQuestionWithShortDelay } =
    useContext(StoreContext)
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay
  const label = confident ? getString('confident') : getString('notConfident')

  const onClickHandler = () => {
    setAnswered(true)
    recordConfidenceLevel(confident === true)
    nextQuestionWithShortDelay()
  }

  return (
    <Fab
      className={classes.threeJobsAnswerButton}
      variant="extended"
      size="large"
      disabled={answered || isSubmitting}
      onClick={() => {
        handleClick(onClickHandler)
      }}
    >
      {label}
    </Fab>
  )
}

export default ConfidenceButton
