import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import styles from '../css-styles'
import usePreventDoubleClick from '../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({
  justifyFlexEnd: styles.justifyFlexEnd,
  marginRight: styles.marginRight,
})

const ButtonNext = forwardRef(({ children, disable, onClick }, ref) => {
  const classes = useStyles()
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay
  return (
    <CardActions className={classes.justifyFlexEnd}>
      <Button
        onClick={() => {
          handleClick(onClick)
        }}
        size="large"
        variant="contained"
        color="primary"
        className={classes.marginRight}
        disabled={disable || isSubmitting}
        id="next-button"
        ref={ref}
      >
        {children}
        <NavigateNext fontSize="large">{children}</NavigateNext>
      </Button>
    </CardActions>
  )
})

ButtonNext.defaultProps = {
  disable: false,
}

ButtonNext.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disable: PropTypes.bool,
}

export default ButtonNext
