import React, { useState, useContext, useEffect } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from './PracticeChip'
import AnswerButton from './AnswerButton'
import Timer from './Timer'
import { StoreContext } from '../StoreContext'
import Explanation from './Explanation'
import useQuestionStartTime from '../hooks/useQuestionStartTime'
import { RC_CATEGORIES_RECALL, RC_CATEGORIES_LEARNING } from '../constants'
import styles from '../css-styles'
import Loading from './Loading'

const useStyles = makeStyles({
  questionImage: styles.questionImage,
  stimuli: styles.stimuli,
  answerGrid4x4: styles.answerGrid4x4,
})

const Question = ({ question }) => {
  const classes = useStyles()
  const [showCorrect, setShowCorrect] = useState(false)
  const {
    askUserAreYouThere,
    didAnswerCorrectly,
    getPhase,
    getString,
    getTimer,
    isPractice,
    nextQuestion,
    onTimeExpired,
    afterFeedback,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  } = useContext(StoreContext)
  const phase = getPhase(afterFeedback)
  useQuestionStartTime()
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  const [preloadedImage] = useState(new Image())
  if (preloadedImage.src === '') {
    preloadedImage.src = question.image
  }

  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (!imageLoaded) {
    return <Loading />
  }

  if (
    (phase.react_component === RC_CATEGORIES_RECALL &&
      isPractice &&
      didAnswerCorrectly) ||
    showCorrect
  ) {
    return <Explanation reason={question.explanation} onClick={onTimeExpired} />
  }

  let timeExpired = () => setShowCorrect(true)

  if (phase.react_component === RC_CATEGORIES_LEARNING && !isPractice) {
    if (didAnswerCorrectly === null) {
      timeExpired = askUserAreYouThere
    } else {
      timeExpired = nextQuestion
    }
  }

  const headline = () => {
    return phase.react_component === RC_CATEGORIES_RECALL
      ? getString('tapPair')
      : getString('selectSimilar')
  }

  return (
    <Card>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography gutterBottom>{headline()}</Typography>
        {question.image && (
          <Grow in>
            <CardMedia
              className={classes.questionImage}
              image={question.image}
              caption={question.image_caption}
            />
          </Grow>
        )}
        <Grow in>
          <Typography className={classes.stimuli} variant="h4" gutterBottom>
            {question.text}
          </Typography>
        </Grow>
        <Grow in>
          <CardActions className={classes.answerGrid4x4} disableSpacing>
            {question.answers.map((answer) => {
              return <AnswerButton key={answer.id} answer={answer} />
            })}
          </CardActions>
        </Grow>
      </CardContent>
      {getTimer() && (
        <Timer duration={getTimer()} onTimeExpired={timeExpired} />
      )}
    </Card>
  )
}

export default Question
