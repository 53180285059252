import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import AnswerButton from '../Signs/AnswerButton'
import { StoreContext } from '../../StoreContext'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  signHero: styles.signHero,
  justifySpaceEvenly: styles.justifySpaceEvenly,
})

const GroceriesPicMatching = ({ q }) => {
  const classes = useStyles()
  useQuestionStartTime()
  const {
    getString,
    isPractice,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  } = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])
  if (!imageLoaded) {
    return <Loading />
  }
  return (
    <Card className={classes.width95}>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography>{getString('groceriesPicMatchingPrompt')}</Typography>
      </CardContent>
      <Grow in>
        <CardMedia className={classes.signHero} image={q.image} />
      </Grow>
      <CardActions className={classes.justifySpaceEvenly} disableSpacing>
        <AnswerButton
          answer={{
            text: getString('yes'),
            isCorrect: q.is_correct,
          }}
        />
        <AnswerButton
          answer={{
            text: getString('no'),
            isCorrect: !q.is_correct,
          }}
        />
      </CardActions>
    </Card>
  )
}

export default GroceriesPicMatching
