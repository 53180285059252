import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Grow,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CheckCircle, IndeterminateCheckBox } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import ButtonNext from '../ButtonNext'
import { StoreContext } from '../../StoreContext'
import PracticeChip from '../PracticeChip'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import Timer from '../Timer'
import styles from '../../css-styles'
import Loading from '../Loading'
import usePreventDoubleClick from '../../hooks/doubleSubmitPrevention'

const useStyles = makeStyles({
  width95: styles.width95,
  textAlignCenter: styles.textAlignCenter,
  fNameFaceHero: styles.fNameFaceHero,
})

const Recall = ({ q }) => {
  const store = useContext(StoreContext)
  const { getString } = store
  const classes = useStyles()
  const refPicture = useRef(null)
  const [formInput, setFormInput] = useState('')
  useQuestionStartTime()

  const timer = store.getTimer()
  const onTimeExpired =
    store.attemptNumber !== 0 ? store.nextQuestion : store.askUserAreYouThere
  const { setDidAnswerCorrectly, incrementAttemptNumber } = store
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())
  const [handleClick, isSubmitting] = usePreventDoubleClick() // Use the hook with a custom delay
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  const { setQuestionStartTime, incrementLoadingPanelCount } = store

  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height
        const fullHeight = window.innerHeight
        // If the viewport height is smaller than the full height, the keyboard is likely showing
        setIsKeyboardVisible(viewportHeight < fullHeight)
      }
    }

    // Add event listener for viewport resize
    window.visualViewport.addEventListener('resize', handleResize)

    // Clean up event listener on component unmount
    return () => {
      window.visualViewport.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (formInput !== '') {
      const correct =
        formInput[0].toLowerCase() === q.target_name[0].toLowerCase()
      setDidAnswerCorrectly(correct)
      incrementAttemptNumber()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInput, q])

  const createAdornment = () => {
    let adornment = null
    if (store.hasVisualFeedback && formInput !== '') {
      adornment = {
        endAdornment: (
          <InputAdornment position="end">
            {store.didAnswerCorrectly ? (
              <CheckCircle fontSize="large" htmlColor={green[500]} />
            ) : (
              <IndeterminateCheckBox fontSize="large" color="error" />
            )}
          </InputAdornment>
        ),
      }
    }
    return adornment
  }

  const isDisabled = formInput !== undefined && formInput.length === 0

  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (q && preloadedImage.src === '') {
    preloadedImage.src = q.target_image
  }

  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      // If we are displaying the loading spinner, display it for at least 3 seconds
      if (!imageLoaded) {
        setTimeout(() => {
          setImageLoaded(preloadedImage.complete)
          setQuestionStartTime(performance.now())
          incrementLoadingPanelCount()
        }, 3000)
      } else {
        // This should already be True, but just in case reset it
        setImageLoaded(preloadedImage.complete)
      }
    } else {
      // Only display loading spinner if the image-preload check takes longer than 300 ms
      if (sleepCounter >= 5 && imageLoaded) {
        setImageLoaded(false)
      }
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [
    imageLoaded,
    setImageLoaded,
    preloadedImage.complete,
    sleepCounter,
    setQuestionStartTime,
    incrementLoadingPanelCount,
  ])

  if (!imageLoaded) {
    return <Loading />
  }
  const handleInput = (event) => {
    // Only remove focus if we have a mobile keyboard and input does not match the previous input
    setFormInput(event.target.value)
    if (
      event.target.value !== '' &&
      event.target.value !== formInput &&
      isKeyboardVisible
    ) {
      event.target.blur()
    }
  }

  return (
    <Card className={classes.width95}>
      <div ref={refPicture} />
      {store.isPractice && (
        <CardContent>
          <PracticeChip />
        </CardContent>
      )}
      <Grow in>
        <CardContent className={classes.textAlignCenter}>
          <CardMedia className={classes.fNameFaceHero} image={q.target_image} />
          <TextField
            required
            autoComplete="off"
            id="firstLetter"
            label={getString('firstLetter')}
            value={formInput}
            margin="normal"
            variant="filled"
            onChange={handleInput}
            onFocus={(event) => {
              const { target } = event
              setTimeout(
                () =>
                  target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  }),
                333
              )
            }}
            InputProps={createAdornment()}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            inputProps={{ maxLength: 1, size: 10 }}
          />
          <ButtonNext
            disable={isDisabled || isSubmitting}
            onClick={() => {
              handleClick(store.nextQuestionWithShortDelay)
            }}
          >
            {getString('nextButtonLabel')}
          </ButtonNext>
        </CardContent>
      </Grow>
      {timer && <Timer duration={timer} onTimeExpired={onTimeExpired} />}
    </Card>
  )
}

export default Recall
